#relatorios {
    padding: 1rem 2rem;
}

.pikers {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.date-piker {
    margin-left: 1rem !important;
}

.loading-spinner {
    margin-left: 1rem;
    padding: 1rem;
}
